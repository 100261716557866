import { render, staticRenderFns } from "./Comissao.vue?vue&type=template&id=3127a969&scoped=true&"
import script from "./Comissao.vue?vue&type=script&lang=js&"
export * from "./Comissao.vue?vue&type=script&lang=js&"
import style0 from "./Comissao.vue?vue&type=style&index=0&id=3127a969&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3127a969",
  null
  
)

export default component.exports