import { http } from './config'
import authHeader from './auth-header';

export default {

    lista_comissao: (dataI, dataF, nomeEmpresa, tipo, filtro, opcao, produto) => {

        const params= {
            'data-inicial': dataI,
            'data-final': dataF,
            'empresa': nomeEmpresa,
            'tipo': tipo,
            'filtro': filtro,
            'opcao': opcao,
            'produto': produto,
        }

        return http.get('/comissao-dash/lista-comissao/', {
        params: params,
        headers: authHeader()   
        })  
        },

    

        lista_regras: (dataF, nomeEmpresa) => {

          const params= {
            'data-final': dataF,
            'empresa': nomeEmpresa
          }
  
          return http.get('/comissao-dash/lista-regras/', {
          params: params,
          headers: authHeader()   
          })  
          },

          lista_comissao_venda_xls: (dataI, dataF,  nomeEmpresa, tipo, filtro, opcao, produto) => {

            
            const params = {
                'data-inicial': dataI,
                'data-final': dataF,
                'empresa': nomeEmpresa,
                'tipo': tipo,
                'filtro': filtro,
                'opcao': opcao,
                'produto': produto,
              };
    
           
              return http.get('/planilha-dash/lista-comissao-venda-xls', {
                params: params,
                responseType: 'blob', // Configurar responseType como 'blob' para baixar um arquivo binário
                headers: authHeader() // Certifique-se de incluir os cabeçalhos de autenticação aqui
    
              });
            },
            lista_comissao_venda_alelo_xls: (dataI, dataF,  nomeEmpresa, tipo, filtro, opcao, produto) => {

            
              const params = {
                  'data-inicial': dataI,
                  'data-final': dataF,
                  'empresa': nomeEmpresa,
                  'tipo': tipo,
                  'filtro': filtro,
                  'opcao': opcao,
                  'produto': produto,
                };
      
             
                return http.get('/planilha-dash/lista-comissao-venda-alelo-xls', {
                  params: params,
                  responseType: 'blob', // Configurar responseType como 'blob' para baixar um arquivo binário
                  headers: authHeader() // Certifique-se de incluir os cabeçalhos de autenticação aqui
      
                });
              },

          lista_comissao_venda: (dataI, dataF, nomeEmpresa, tipo, filtro, opcao, produto) => {

            const params= {
                'data-inicial': dataI,
                'data-final': dataF,
                'empresa': nomeEmpresa,
                'tipo': tipo,
                'filtro': filtro,
                'opcao': opcao,
                'produto': produto,
            }
    
            return http.get('/comissao-dash/lista-comissao-venda/', {
            params: params,
            headers: authHeader()   
            })  
            },


          lista_comissao_entregas: (dataI, dataF, nomeEmpresa, tipo, filtro, opcao, produto) => {

            const params= {
                'data-inicial': dataI,
                'data-final': dataF,
                'empresa': nomeEmpresa,
                'tipo': tipo,
                'filtro': filtro,
                'opcao': opcao,
                'produto': produto,
            }
    
            return http.get('/comissao-dash/lista-comissao-entregas/', {
            params: params,
            headers: authHeader()   
            })  
            },


            lista_comissao_alelo_total: (dataI, dataF, nomeEmpresa, tipo, filtro, opcao, produto) => {

              const params= {
                  'data-inicial': dataI,
                  'data-final': dataF,
                  'empresa': nomeEmpresa,
                  'tipo': tipo,
                  'filtro': filtro,
                  'opcao': opcao,
                  'produto': produto,
              }
      
              return http.get('/planilha-dash/lista-comissao-alelo-todos/', {
              params: params,
              headers: authHeader()   
              })  
              },


            
            lista_comissao_entrega_xls: (dataI, dataF,  nomeEmpresa, tipo, filtro, opcao, produto) => {

            
              const params = {
                  'data-inicial': dataI,
                  'data-final': dataF,
                  'empresa': nomeEmpresa,
                  'tipo': tipo,
                  'filtro': filtro,
                  'opcao': opcao,
                  'produto': produto,
                };
      
             
                return http.get('/planilha-dash/lista-comissao-entrega-xls', {
                  params: params,
                  responseType: 'blob', // Configurar responseType como 'blob' para baixar um arquivo binário
                  headers: authHeader() // Certifique-se de incluir os cabeçalhos de autenticação aqui
      
                });
              },


              lista_comissao_entrega_alelo_xls: (dataI, dataF,  nomeEmpresa, tipo, filtro, opcao, produto) => {

            
                const params = {
                    'data-inicial': dataI,
                    'data-final': dataF,
                    'empresa': nomeEmpresa,
                    'tipo': tipo,
                    'filtro': filtro,
                    'opcao': opcao,
                    'produto': produto,
                  };
        
               
                  return http.get('/planilha-dash/lista-comissao-entrega-alelo-xls', {
                    params: params,
                    responseType: 'blob', // Configurar responseType como 'blob' para baixar um arquivo binário
                    headers: authHeader() // Certifique-se de incluir os cabeçalhos de autenticação aqui
        
                  });
                },
  



        lista_comissao_funeraria: (dataI, dataF, nomeEmpresa, tipo, filtro, opcao, produto) => {

          const params= {
              'data-inicial': dataI,
              'data-final': dataF,
              'empresa': nomeEmpresa,
              'tipo': tipo,
              'filtro': filtro,
              'opcao': opcao,
              'produto': produto,
          }
  
          return http.get('/comissao-dash/lista-comissao-funeraria/', {
          params: params,
          headers: authHeader()   
          })  
          },

          


          lista_vendedores: () => {

            return http.get('/comissao-dash/lista-vendedores/', {
            headers: authHeader()   
            })  
          },

          lista_locais: () => {

            return http.get('/comissao-dash/lista-locais/', {
            headers: authHeader()   
            })  
          },

          adiciona: (controle) => {
            return http.post('/comissao-dash/salvar/', controle, {
                headers: authHeader()
            });
          },

          adiciona_conformidade: (controle) => {
            return http.post('/comissao-dash/salvar-conformidade/', controle, {
                headers: authHeader()
            });
          },

        deletar: (id) => {
          return http.delete(`/comissao-dash/deletar/${id}`, {
            headers: authHeader()
          });
        },

        deletar_conformidade: (id) => {
          return http.delete(`/comissao-dash/deletar-conformidade/${id}`, {
            headers: authHeader()
          });
        },
        
          lista_controle: () => {

              return http.get('/comissao-dash/lista-controle/', {
              headers: authHeader()   
              })  
          },

          lista_conformidade: () => {

            return http.get('/comissao-dash/lista-conformidade/', {
            headers: authHeader()   
            })  
        },

          lista_comissao_funeraria_total: (dataI, dataF, nomeEmpresa, tipo, filtro, opcao, produto) => {

            const params= {
                'data-inicial': dataI,
                'data-final': dataF,
                'empresa': nomeEmpresa,
                'tipo': tipo,
                'filtro': filtro,
                'opcao': opcao,
                'produto': produto,
            }
    
            return http.get('/comissao-dash/lista-comissao-funeraria-total/', {
            params: params,
            headers: authHeader()   
            })  
            },

            
            lista_comissao_alelo_tudo_xls: (dataI, dataF,  nomeEmpresa, tipo, filtro, opcao, produto) => {

            
              const params = {
                  'data-inicial': dataI,
                  'data-final': dataF,
                  'empresa': nomeEmpresa,
                  'tipo': tipo,
                  'filtro': filtro,
                  'opcao': opcao,
                  'produto': produto,
                };
      
             
                return http.get('/planilha-dash/lista-comissao-alelo-todos-xls', {
                  params: params,
                  responseType: 'blob', // Configurar responseType como 'blob' para baixar um arquivo binário
                  headers: authHeader() // Certifique-se de incluir os cabeçalhos de autenticação aqui
      
                });
        },

          lista_comissao_alelo_xls: (dataI, dataF,  nomeEmpresa, tipo, filtro, opcao, produto) => {

            
              const params = {
                  'data-inicial': dataI,
                  'data-final': dataF,
                  'empresa': nomeEmpresa,
                  'tipo': tipo,
                  'filtro': filtro,
                  'opcao': opcao,
                  'produto': produto,
                };
      
             
                return http.get('/planilha-dash/lista-comissao-alelo-xls', {
                  params: params,
                  responseType: 'blob', // Configurar responseType como 'blob' para baixar um arquivo binário
                  headers: authHeader() // Certifique-se de incluir os cabeçalhos de autenticação aqui
      
                });
        },

        lista_comissao_xls: (dataI, dataF,  nomeEmpresa, tipo, filtro, opcao, produto) => {

            
            const params = {
                'data-inicial': dataI,
                'data-final': dataF,
                'empresa': nomeEmpresa,
                'tipo': tipo,
                'filtro': filtro,
                'opcao': opcao,
                'produto': produto,
              };
    
           
              return http.get('/planilha-dash/lista-comissao-xls', {
                params: params,
                responseType: 'blob', // Configurar responseType como 'blob' para baixar um arquivo binário
                headers: authHeader() // Certifique-se de incluir os cabeçalhos de autenticação aqui
    
              });
            },

          
              lista_comissao_funeraria_xls: (dataI, dataF,  nomeEmpresa, tipo, filtro, opcao, produto) => {

            
                const params = {
                    'data-inicial': dataI,
                    'data-final': dataF,
                    'empresa': nomeEmpresa,
                    'tipo': tipo,
                    'filtro': filtro,
                    'opcao': opcao,
                    'produto': produto,
                  };
        
               
                  return http.get('/planilha-dash/lista-comissao-funeraria-xls', {
                    params: params,
                    responseType: 'blob', // Configurar responseType como 'blob' para baixar um arquivo binário
                    headers: authHeader() // Certifique-se de incluir os cabeçalhos de autenticação aqui
        
                  });
                },

                lista_comissao_funeraria_alelo_xls: (dataI, dataF,  nomeEmpresa, tipo, filtro, opcao, produto) => {

            
                  const params = {
                      'data-inicial': dataI,
                      'data-final': dataF,
                      'empresa': nomeEmpresa,
                      'tipo': tipo,
                      'filtro': filtro,
                      'opcao': opcao,
                      'produto': produto,
                    };
          
                 
                    return http.get('/planilha-dash/lista-comissao-funeraria-alelo-xls', {
                      params: params,
                      responseType: 'blob', // Configurar responseType como 'blob' para baixar um arquivo binário
                      headers: authHeader() // Certifique-se de incluir os cabeçalhos de autenticação aqui
          
                    });
                  }



            
}
